import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { ControlContainer } from '@angular/forms';

@Directive({
  selector: '[appInputText]',
  standalone: true,
})
export class InputTextDirective {
  @Input() regex!: string;
  @Input() case: 'default' | 'upper' | 'lower' | 'cap' = 'default';
  @Input() type: 'text' | 'number' = 'text';
  @Input() appFocusHandler: string = '';

  constructor(
    private el: ElementRef,
    private controlContainer: ControlContainer,
  ) {}

  capitalize(value: string) {
    return value
      .split(' ')
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  @HostListener('input', ['$event']) onInput() {
    let value = this.el.nativeElement.value;
    if (this.regex || this.regex !== '') {
      value = value.replace(new RegExp(this.regex, 'g'), '');
    }

    this.applyCaseTransformation(value);

    this.el.nativeElement.value = String(value).trimStart();
  }

  @HostListener('focus', ['$event'])
  onFocus() {
    const control = this.controlContainer.control?.get(this.appFocusHandler);
    if (control) {
      let value = this.el.nativeElement.value;
      if (this.type === 'number') {
        value = value ? Number(value.replace(/[^0-9.-]/g, '')) : '';
        if (isNaN(value)) value = '';
      } else if (this.regex || this.regex !== '') {
        value = value.replace(new RegExp(this.regex, 'g'), '');
      }
      this.applyCaseTransformation(value);
      control.setValue(String(value).trimStart());
      control.markAsPristine();
      control.updateValueAndValidity();
    }
  }

  applyCaseTransformation(value: string): string {
    switch (this.case) {
      case 'upper':
        return String(value).toUpperCase();
      case 'lower':
        return String(value).toLowerCase();
      case 'cap':
        return this.capitalize(value);
      default:
        return value; // No transformation
    }
  }
}
